


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { MonthEvent } from './types'
import { Meeting } from '@tada-team/tdproto-ts'
import { calendarStore } from '@/store'

@Component({
  components: {
    CompactMeetingCard: () => import('@/components/Meetings/Cards/Compact.vue'),
    HasMoreMeetingCard: () => import('@/components/Meetings/Cards/HasMore.vue'),
  },
})
export default class DayCellInMonth extends Vue {
  @Prop({
    required: true,
    type: Array,
  }) events!: MonthEvent[]

  private calculatedMonthStyle (event: MonthEvent) {
    const cardCount = Math.min(event.cardCount, 4)
    return {
      top: `calc(${100 * event.index / cardCount}% - 4px)`,
      height: `calc(${100 / cardCount}% - 3px)`,
      left: '4px',
      width: 'calc(100% - 8px)',
    }
  }

  private showMoreMeetings (meeting: Meeting) {
    calendarStore.actions.changeViewMode('day')
    this.$emit('changeCalendarDate', meeting.startAt)
  }
}

