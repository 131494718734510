















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CurrentTimeLine extends Vue {
  @Prop({
    required: true,
    type: Number,
  }) timeStartPos!: number

  @Prop({
    required: true,
    type: String,
  }) currentTime!: string

  get currentTimeLineStyle () {
    return {
      top: this.timeStartPos + 'px',
    }
  }
}
