var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar"},[_c('Header',{attrs:{"date":_vm.date},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}}),_c('QCalendar',{ref:"calendar",staticClass:"fit overflow-auto column",class:{
      'calendar-day': _vm.viewMode === 'day',
      'calendar-week': _vm.viewMode === 'week',
      'calendar-month': _vm.viewMode === 'month'
    },attrs:{"value":_vm.date,"view":_vm.viewMode,"hide-header":_vm.viewMode === 'day',"hour24-format":_vm.is24HoursFormat,"interval-start":0,"interval-count":25,"locale":_vm.$i18n.locale,"interval-height":124,"weekdays":[1,2,3,4,5,6, 0],"day-height":130,"short-weekday-label":""},on:{"input":_vm.openDay},scopedSlots:_vm._u([{key:"day-container",fn:function(){return [(_vm.isShowCurrentTimeLine)?_c('CurrentTimeLine',{attrs:{"time-start-pos":_vm.timeStartPos,"current-time":_vm.currentTime}}):_vm._e()]},proxy:true},{key:"day-body",fn:function(ref){
    var timeStartPos = ref.timeStartPos;
    var timeDurationHeight = ref.timeDurationHeight;
    var timestamp = ref.timestamp;
return [_vm._l((_vm.getEvents(timestamp)),function(event,index){return [(_vm.viewMode === 'day')?_c(_vm.dayCardComponent(event),{key:index,tag:"Component",staticClass:"absolute calendar-card",style:(_vm.calculatedDayStyle(
            event.meeting,
            timeDurationHeight(event.meeting.duration),
            timeStartPos(new Date(event.meeting.startAt).toLocaleTimeString('ru')),
            event.indent,
            event.width
          )),attrs:{"is-show-interval":"","is-show-icon":"","narrow-mode":"","is-busy":event.meeting.meetingRecipientStatus !== 'MEETING_RECIPIENT_STATUS_MINE',"is-show-members":event.meeting.duration >= 60,"is-calendar-card":"","meeting":event.meeting}}):(_vm.viewMode === 'week')?_c('CompactMeetingCard',{key:index,staticClass:"absolute calendar-card column",style:(_vm.calculatedWeekStyle(
            event.meeting,
            timeDurationHeight(event.meeting.duration),
            timeStartPos(new Date(event.meeting.startAt).toLocaleTimeString('ru')),
            event.indent,
            event.width
          )),attrs:{"is-show-interval":"","is-show-icon":"","narrow-mode":"","is-calendar-card":"","is-show-actions":"","meeting":event.meeting}}):_vm._e()]})]}},{key:"day",fn:function(ref){
          var timestamp = ref.timestamp;
return [_c('DayCellInMonth',{attrs:{"events":_vm.getMonthEvents(timestamp)},on:{"changeCalendarDate":function (date) { return _vm.$emit('changeCalendarDate', date); }}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }