





















































































import { Component, Vue, Ref, Prop, Watch, VModel } from 'vue-property-decorator'
import { QCalendar, parseDate, Timestamp, isBetweenDates, addToDate } from '@quasar/quasar-ui-qcalendar'
import '@quasar/quasar-ui-qcalendar/dist/index.css'
import { calendarStore } from '@/store'
import { Contact, Meeting } from '@tada-team/tdproto-ts'
import { Event, MonthEvent } from './types'
import DayCellInMonth from './DayCellInMonth.vue'
import CurrentTimeLine from './CurrentTimeLine.vue'
import { date } from 'quasar'

const { getWeekOfYear, isSameDate } = date

@Component({
  components: {
    QCalendar,
    FullMeetingCard: () => import('@/components/Meetings/Cards/Full.vue'),
    CompactMeetingCard: () => import('@/components/Meetings/Cards/Compact.vue'),
    HasMoreMeetingCard: () => import('@/components/Meetings/Cards/HasMore.vue'),
    Header: () => import('./Header.vue'),
    DayCellInMonth,
    CurrentTimeLine,
  },
})
export default class Calender extends Vue {
  @VModel({
    type: Object,
  }) contact!: Contact

  @Ref() readonly calendar!: QCalendar

  @Prop({
    required: true,
    type: String,
  }) readonly selectedDate!: string

  @Prop({
    required: true,
    type: Array,
  }) readonly meetings!: Meeting[]

  private intervalId: null | number = null
  private timeStartPos = 0
  private time = ''

  private maxDayCardWidth = 330

  mounted () {
    this.updateCurrentTimeLine()
    // update current timeline every 1 minute
    this.intervalId = setInterval(() => {
      this.updateCurrentTimeLine()
    }, 60000)
    this.scrollToCurrentTime()
  }

  beforeDestroy () {
    this.intervalId && clearInterval(this.intervalId)
  }

  get viewMode () {
    return calendarStore.state.viewMode
  }

  get isShowCurrentTimeLine () {
    const selectedDate = new Date(this.selectedDate)
    const currentDay = new Date()
    if (this.viewMode === 'day') {
      return isSameDate(selectedDate, currentDay, 'day')
    } if (this.viewMode === 'week') {
      return getWeekOfYear(selectedDate) === getWeekOfYear(currentDay)
    }
    return false
  }

  @Watch('viewMode')
  private watchViwMode () {
    this.updateCurrentTimeLine()
    this.$nextTick(this.scrollToCurrentTime)
  }

  @Watch('selectedDate')
  private watchSelectedDate () {
    this.$nextTick(this.scrollToCurrentTime)
  }

  get date () {
    return parseDate(new Date(this.selectedDate))?.date ?? ''
  }

  get currentTime () {
    return this.time
  }

  set currentTime (val: string) {
    this.time = val
  }

  private updateCurrentTimeLine () {
    if (!this.calendar) return
    const now = new Date()
    const time = parseDate(now)
    if (!time) return
    this.currentTime = now.toLocaleString(this.$i18n.locale, {
      minute: '2-digit',
      hour: '2-digit',
    })
    this.timeStartPos = this.calendar.timeStartPos(now.toLocaleTimeString('ru')) || 0
  }

  private getMeetingsByDate (date: Timestamp) {
    return this.meetings.filter(m => {
      const d = new Date(m.startAt)
      return d.getFullYear() === date.year &&
        (d.getMonth() + 1) === date.month &&
        d.getDate() === date.day
    }).sort((m1, m2) => {
      const start1 = new Date(m1.startAt)
      const start2 = new Date(m2.startAt)
      return start1.getTime() - start2.getTime()
    })
  }

  private getEvents (date: Timestamp): Event [] {
    // We take only meetings corresponding to the period
    const meetings = this.getMeetingsByDate(date)

    const events: Event[] = []

    // We go through the array of meetings and look for the intersection
    meetings.forEach(meeting => {
      const date = new Date(meeting.startAt)
      let indent = 0
      let width = 1
      const indexes: number[] = []

      const startTime = parseDate(date)
      if (!startTime) return
      const endTime = addToDate(startTime, { minute: meeting.duration - 1 })

      events.forEach((event, index) => {
        const startTime2 = parseDate(new Date(event.meeting.startAt))
        if (!startTime2) return
        const duration = event.meeting.duration < 15 ? 15 : event.meeting.duration - 1
        const endTime2 = addToDate(startTime2, { minute: duration })
        if (
          isBetweenDates(startTime, startTime2, endTime2, true) ||
          isBetweenDates(endTime, startTime2, endTime2, true)
        ) {
          indent += 1
          width = 1 / (indent + 1)
          indexes.push(index)
        }
      })
      indexes.forEach(i => { events[i].width = width })
      events.push({
        indent,
        meeting,
        width,
      })
    })
    this.updateCurrentTimeLine()
    return events
  }

  private getMonthEvents (date: Timestamp): MonthEvent[] {
    const meetings = this.meetings.filter(meeting => {
      const startAt = parseDate(new Date(meeting.startAt))
      return startAt?.month === date.month && startAt?.day === date.day && startAt.year === date.year
    })
    const meetingsLength = meetings.length
    return meetings.slice(0, 4).map((meeting, index) => ({ meeting, index, cardCount: meetingsLength }))
  }

  private calculatedDayStyle (meeting: Meeting, height: number, top: number, indent: number, width: number) {
    const fullWidth = 100
    const startIndent = 55
    const cardCount = 1 / width
    const difference = startIndent / cardCount
    const baseIndent = 4
    const left = `calc(${fullWidth * width * indent}% + ${startIndent - (indent * difference)}px)`
    return {
      top: `${top + 4}px`,
      height: `${height - 3}px`,
      left: indent === 0 ? '55px' : left,
      width: `calc(${fullWidth * width}% - ${difference + baseIndent}px)`,
      maxWidth: `${this.maxDayCardWidth}px`,
      opacity: this.isPastMeeting(meeting) ? 0.5 : 1,
    }
  }

  private calculatedWeekStyle (meeting: Meeting, height: number, top: number, indent: number, width: number) {
    return {
      top: `${top + 4}px`,
      height: `${height - 5}px`,
      left: indent === 0 ? '4px' : `${100 * width * indent}%`,
      width: `calc(${100 * width}% - ${indent === 0 ? 8 : 4}px)`,
      opacity: this.isPastMeeting(meeting) ? 0.5 : 1,
    }
  }

  private dayCardComponent (event: Event) {
    const calendarWidth = this.calendar?.$el.scrollWidth ?? 0
    if (calendarWidth * event.width < 140) return 'CompactMeetingCard'
    if (event.meeting.duration >= 30) return 'FullMeetingCard'
    else return 'CompactMeetingCard'
  }

  private openDay (date: string) {
    calendarStore.actions.changeViewMode('day')
    this.$emit('changeCalendarDate', date)
  }

  private isPastMeeting (meeting: Meeting) {
    const now = new Date()
    const startDate = new Date(meeting.startAt)
    const end = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      startDate.getHours(),
      startDate.getMinutes() + meeting.duration,
    )
    return now.getTime() > end.getTime()
  }

  private get is24HoursFormat () {
    return this.$i18n.locale === 'ru'
  }

  private scrollToCurrentTime () {
    const selectedDate = new Date(this.selectedDate)
    const time = new Date()
    if (
      selectedDate.getFullYear() !== time.getFullYear() ||
      selectedDate.getMonth() !== time.getMonth() ||
      selectedDate.getDate() !== time.getDate() ||
      time.getHours() <= 2
    ) return
    time.setHours(time.getHours() - 2)
    this.calendar.scrollToTime(time.toLocaleTimeString())
  }
}
